html,
body {
  border: 0;
  font-family: 'Roboto', Arial, sans-serif;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  position: relative;
  top: 0;
  left: 0;
  font-weight: 400;
  height: 100%;
  letter-spacing: 1px;
  background: #f3f3f3;
  color: #8a95a6;
  font-size: 15px;
}
::placeholder {
  color: #b2b2b2;
  opacity: 1;
}
:-ms-input-placeholder {
  color: #b2b2b2;
}
::-ms-input-placeholder {
  color: #b2b2b2;
}
a:hover {
  color: inherit;
  border: 0;
  text-decoration: none;
}
a:focus,
a:active,
input:focus,
input:active,
button:focus,
button:active,
textarea:focus,
textarea:active {
  outline: none !important;
}
input,
a,
.btn,
textarea {
  box-shadow: none !important;
}
.container {
  max-width: 98%;
}
@media (min-width: 1200px) {
  .container {
    max-width: 1180px;
  }
}
div,
span,
object,
iframe,
img,
table,
caption,
thead,
tbody,
tfoot,
tr,
tr,
td,
article,
aside,
canvas,
details,
figure,
hgroup,
menu,
nav,
footer,
header,
section,
summary,
mark,
audio,
video {
  border: 0;
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
address,
cit,
code,
del,
dfn,
em,
ins,
q,
samp,
small,
strong,
sub,
sup,
b,
i,
hr,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
legend,
label {
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
}
article,
aside,
canvas,
figure,
figure img,
figcaption,
hgroup,
footer,
header,
nav,
section,
audio,
video {
  display: block;
}
.uppercase {
  text-transform: uppercase;
}
a img {
  border: 0;
}
:focus {
  outline: 0;
}
img {
  max-width: 100%;
}
.relative {
  position: relative;
  top: 0;
  left: 0;
}
.bold {
  font-weight: bold;
}
.semibold {
  font-weight: 500;
}
h3 {
  color: #46556c;
  font-size: 20px;
  font-weight: 500;
}
.huge {
  font-size: 25px;
}
.big {
  font-size: 20px;
}
.medium {
  font-size: 16px;
}
.small {
  font-size: 13px;
}
.green {
  color: #0c968b;
}
.fluorescent,
.fluorescent * {
  color: #5ce149;
}
.white,
.white * {
  color: #fff;
}
.light-grey-bg {
  background: #f3f3f3;
}
.grey-bg {
  background: #ebebeb;
}
.btn {
  border: 0;
  font-size: 15px;
  padding: 7px 10px;
  text-transform: uppercase;
}
.btn:disabled {
  background: #58716f !important;
}
.btn:disabled img.image-spinner {
  height: 20px;
  margin-right: 6px;
  vertical-align: middle;
  display: inline-block;
  margin-top: -3px;
}
.btn-primary {
  background: #0c968b;
}
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  background: #2c5964 !important;
}
.btn-secondary {
  background: #adb9c9;
}
.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:focus {
  background: #8a95a6 !important;
}
.btn-fluorescent {
  background: #5ce149;
  color: #fff;
}
.btn-fluorescent:hover,
.btn-fluorescent:active,
.btn-fluorescent:focus {
  background: #3cba2a !important;
  color: #fff;
}
.btn-blue {
  background: #3b5997;
  color: #fff;
  position: relative;
  top: 0;
  left: 0;
}
.btn-blue img {
  position: absolute;
  left: 17px;
  top: 13px;
}
.btn-blue:hover,
.btn-blue:active,
.btn-blue:focus {
  background: #264380;
  color: #fff;
}
.btn-white {
  background: #f6f6f6;
  border: 1px solid #f2f2f2;
  color: #666;
  position: relative;
  top: 0;
  left: 0;
}
.btn-white img {
  position: absolute;
  left: 10px;
  top: 11px;
}
.btn-white:hover,
.btn-white:active,
.btn-white:focus {
  background: #ddd;
}
.btn-white[disabled] {
  color: #fff;
}
.spin {
  -webkit-animation: spin 2000ms infinite linear;
  -moz-animation: spin 2000ms infinite linear;
  -ms-animation: spin 2000ms infinite linear;
  animation: spin 3000ms infinite linear;
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -ms-animation-play-state: paused;
  animation-play-state: paused;
}
@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
  }
}
@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.vertical-center {
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
.error p {
  border-radius: 4px;
  padding: 5px;
  color: #9e1818 !important;
  font-weight: 500 !important;
  text-transform: uppercase !important;
  font-size: 12px !important;
}
header {
  background: #08b28a;
  /* Old browsers */
  background: -moz-linear-gradient(left, #08b28a 0%, #2c5963 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #08b28a 0%, #2c5963 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #08b28a 0%, #2c5963 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#08b28a', endColorstr='#2c5963', GradientType=1);
  /* IE6-9 */
  padding: 20px 0;
  position: sticky;
  top: 0;
  z-index: 10;
}
header img {
  height: 50px;
}
header .nav {
  display: block;
  text-align: right;
}
header .nav a.btn-primary {
  background: #0c968b;
  border-radius: 20px;
  border: 2px solid #0c968b;
}
header .nav a.btn-primary:hover,
header .nav a.btn-primary:active,
header .nav a.btn-primary:focus {
  background: #2c5964 !important;
  border-color: #2c5964;
}
header .nav a,
header .nav button {
  color: #fff;
  display: inline-block;
  padding: 7px 20px;
  background: transparent;
  border: 0;
  margin: 2px 0;
  text-transform: uppercase;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
header .nav a.outline,
header .nav button.outline {
  border: 2px solid #fff;
  border-radius: 20px;
}
header .nav a.outline:hover,
header .nav button.outline:hover {
  background: #fff;
  color: #2c5964;
}
header .nav p {
  display: inline-block;
  color: #fff;
  padding-right: 20px;
}
header .nav p > a {
  padding: 0;
  text-transform: none;
}
.main-page .col-xl-3 .offer {
  cursor: pointer;
}
.main-page .offer,
.main-page .learn-more {
  background: #fff;
  position: relative;
  top: 0;
  left: 0;
  overflow: hidden;
  border-radius: 2px;
  box-shadow: 3px 3px 15px transparent;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.main-page .offer.offer-create,
.main-page .learn-more.offer-create {
  overflow: visible;
}
.main-page .offer:hover,
.main-page .learn-more:hover {
  box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.2);
}
.main-page .offer .badge,
.main-page .learn-more .badge {
  position: absolute;
  right: -22px;
  top: 12px;
  background: #ff9947;
  color: #fff;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 0;
  padding: 7px 5px;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 90px;
  z-index: 9;
}
.main-page .offer .img-wrapper,
.main-page .learn-more .img-wrapper {
  height: 165px;
  position: relative;
  top: 0;
  left: 0;
}
.main-page .offer .img-wrapper img,
.main-page .learn-more .img-wrapper img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  max-height: 150px;
  width: auto;
}
.main-page .offer .description,
.main-page .learn-more .description {
  height: 90px;
  overflow: hidden;
}
.main-page .offer .description h3,
.main-page .learn-more .description h3 {
  color: #46556c;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 5px;
}
.main-page .offer .description p,
.main-page .learn-more .description p {
  color: #46556c;
  font-size: 12px;
  margin: 4px 0;
  line-height: 130%;
}
.main-page .offer .buttons,
.main-page .learn-more .buttons {
  height: 85px;
}
.main-page .offer .buttons p,
.main-page .learn-more .buttons p {
  margin-bottom: 5px;
}
.main-page .offer .buttons .btn,
.main-page .learn-more .buttons .btn {
  font-size: 14px;
  display: block;
  width: 100%;
  text-align: center;
  padding: 7px 5px;
  text-transform: none;
  font-weight: 500;
}
.main-page .offer .copy-code,
.main-page .learn-more .copy-code {
  color: #46556c;
  background: #f3f3f3;
  padding: 9px 10px;
  vertical-align: middle;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
}
.main-page .offer .copy-code i,
.main-page .learn-more .copy-code i {
  color: #46556c;
  padding-left: 7px;
  font-size: 14px;
}
.main-page .offer .copy-code:hover,
.main-page .learn-more .copy-code:hover {
  background: #d0d0d0;
}
.main-page .offer-nav {
  background: #fff;
  border-radius: 5px;
}
.main-page .offer-nav .row-nav-items {
  padding-top: 15px;
  padding-bottom: 15px;
}
.main-page .offer-nav .nav-item {
  background: none;
  margin: 0 15px;
}
.main-page .offer-nav .nav-item a {
  text-transform: uppercase;
  background: none;
  border-radius: 0;
  padding: 25px 0;
  color: #8393a7;
  letter-spacing: 0;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.main-page .offer-nav .nav-item a:hover {
  color: #0c968b;
}
.main-page .offer-nav .nav-item a.active {
  color: #0c968b;
  border-bottom: 2px solid #0c968b;
}
.main-page .offer-nav form {
  margin: 0;
  width: 250px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
}
.main-page .offer-nav form input {
  background: #eceff4 url('/images/icon_search.svg') no-repeat 96% center;
  background-size: 25px auto;
  height: 50px;
  padding-right: 40px;
  border: 1px solid #c9d3df;
}
.main-page .offer-nav #refresh {
  display: inline-block;
  vertical-align: middle;
  margin: 0 20px;
}
.main-page .offer-nav #refresh img {
  width: 40px;
  -webkit-animation: spin 2000ms infinite linear;
  -moz-animation: spin 2000ms infinite linear;
  -ms-animation: spin 2000ms infinite linear;
  animation: spin 3000ms infinite linear;
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -ms-animation-play-state: paused;
  animation-play-state: paused;
}
.main-page .offer-nav #refresh img:hover {
  -webkit-animation-play-state: running;
  -moz-animation-play-state: running;
  -ms-animation-play-state: running;
  animation-play-state: running;
}
.main-page .register-form,
.main-page .basic-page {
  background: #fff;
  border-radius: 10px;
  box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.2);
}
.main-page .register-form > img,
.main-page .basic-page > img {
  height: 60px;
}
.main-page .register-form form,
.main-page .basic-page form {
  width: 350px;
  max-width: 100%;
  margin: 0 auto;
}
.main-page .register-form form input,
.main-page .basic-page form input {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  color: #4d4d4d;
  font-size: 17px;
  padding: 5px;
  height: 45px;
  width: 100%;
}
.main-page .register-form form .btn-blue,
.main-page .basic-page form .btn-blue {
  margin-top: 30px;
}
.main-page .register-form form .btn,
.main-page .basic-page form .btn {
  height: 45px;
  line-height: 45px;
  padding: 0 5px;
}
.main-page .register-form form input,
.main-page .basic-page form input {
  padding-left: 35px;
}
.main-page .register-form form #email,
.main-page .basic-page form #email {
  background: #fefffe url('/images/icon_mail.png') no-repeat 10px center;
}
.main-page .register-form form #password,
.main-page .basic-page form #password,
.main-page .register-form form #password-repeat,
.main-page .basic-page form #password-repeat {
  background: #fefffe url('/images/icon_pass.png') no-repeat 10px center;
}
.main-page .register-form form .custom-control-label,
.main-page .basic-page form .custom-control-label {
  font-size: 13px;
}
.main-page .register-form form .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.main-page .basic-page form .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.main-page .register-form form .custom-control-input:checked ~ .custom-control-label::before,
.main-page .basic-page form .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #0c968b;
}
.main-page .register-form form .custom-checkbox .custom-control-label::before,
.main-page .basic-page form .custom-checkbox .custom-control-label::before,
.main-page .register-form form .custom-checkbox .custom-control-label::after,
.main-page .basic-page form .custom-checkbox .custom-control-label::after {
  box-shadow: none !important;
}
.main-page .offer-details a.green {
  color: #0c968b;
}
#login-popup .modal-content {
  border-radius: 25px;
  overflow: hidden;
  border: 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}
#login-popup .modal-content .modal-body {
  padding: 0;
}
#login-popup p {
  font-weight: 300;
  color: #888;
}
#login-popup a.green:hover {
  color: #0c968b;
}
#login-popup #welcome-col,
#login-popup #login-col {
  padding-top: 60px;
  padding-bottom: 40px;
}
#login-popup #welcome-col img {
  height: 60px;
  margin: 50px 0;
}
#login-popup #welcome-col .medium {
  margin-bottom: 80px;
}
#login-popup .close {
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 9;
}
#login-popup .close span {
  font-size: 30px;
}
#login-popup input,
#login-popup .btn {
  height: 45px;
  line-height: 45px;
  padding: 0 5px;
  border-radius: 5px;
}
#login-popup .btn {
  margin: 5px 0;
}
#login-popup form {
  margin-bottom: 0;
}
#login-popup form .btn-primary {
  margin-top: 10px;
}
#login-popup form input {
  border: 1px solid #cccccc;
  padding-left: 35px;
}
#login-popup form #email {
  background: #fefffe url('/images/icon_mail.png') no-repeat 10px center;
}
#login-popup form #password {
  background: #fefffe url('/images/icon_pass.png') no-repeat 10px center;
}
#login-popup form .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
#login-popup form .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #0c968b;
}
#login-popup form .custom-checkbox .custom-control-label::before,
#login-popup form .custom-checkbox .custom-control-label::after {
  box-shadow: none !important;
}
#login-popup .social-login {
  margin-bottom: 30px;
}
#login-popup .social-login .btn-blue {
  margin-top: 0px !important;
}
.form-signin .social-login {
  margin-top: 30px;
}
.form-signin .btn-primary {
  margin-top: 10px !important;
}
#form-contact input,
#form-contact textarea {
  height: 45px;
  line-height: 45px;
  padding: 0 5px;
  border-radius: 5px;
  border: 1px solid #cccccc;
  padding-left: 35px;
}
#form-contact textarea {
  background: #fefffe url('/images/icon_textarea.png') no-repeat 10px 12px;
  height: 150px;
  line-height: normal;
  padding: 12px 5px 12px 35px;
}
#form-contact input {
  background: #fefffe url('/images/icon_mail.png') no-repeat 10px center;
}
.ok-list li {
  list-style: none;
  position: relative;
  top: 0;
  left: 0;
  padding-left: 35px;
}
.ok-list li:before {
  content: '';
  background: url('/images/icon_tick.png') no-repeat center center;
  width: 22px;
  height: 22px;
  position: absolute;
  left: 0;
  top: 0;
}
.learn-more h2 {
  margin-top: 20px;
}
.learn-more ol {
  margin-left: 30px;
}
#back {
  background: none;
  border: 0;
}
#back img {
  width: 15px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 3px;
  margin-top: -3px;
}
#form-offer-add-code .form-group,
#form-offer-add-link .form-group {
  position: relative;
  top: 0;
  left: 0;
  margin-bottom: 0;
}
#form-offer-add-code .form-group label,
#form-offer-add-link .form-group label {
  font-size: 12px;
  display: block;
  margin-bottom: 5px;
}
#form-offer-add-code .form-group input,
#form-offer-add-link .form-group input {
  border: 0;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 0;
  color: #4d4d4d;
  font-size: 17px;
  padding: 0;
  height: 30px;
  width: 100%;
  padding-left: 35px;
}
#form-offer-add-code .form-group i,
#form-offer-add-link .form-group i {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #757575;
  font-size: 18px;
  text-align: center;
  display: block;
  width: 20px;
  height: 30px;
  line-height: 30px;
}
#form-offer-create .form-group {
  position: relative;
  top: 0;
  left: 0;
  padding-left: 30px;
}
#form-offer-create .form-group label {
  font-size: 12px;
  display: block;
  margin-bottom: 5px;
}
#form-offer-create .form-group input,
#form-offer-create .form-group textarea {
  border: 0;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 0;
  color: #4d4d4d;
  font-size: 17px;
  padding: 0;
  height: 30px;
  width: 100%;
}
#form-offer-create .form-group input::placeholder,
#form-offer-create .form-group textarea::placeholder {
  font-size: 12px;
}
#form-offer-create .form-group textarea {
  resize: none;
}
#form-offer-create .form-group i {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #757575;
  font-size: 18px;
  text-align: center;
  display: block;
  width: 20px;
  height: 30px;
  line-height: 30px;
}
#form-offer-create .form-group i.offer-type {
  bottom: auto;
  top: 19px;
}
#form-offer-create .form-group .react-datepicker-wrapper,
#form-offer-create .form-group .react-datepicker__input-container {
  width: 100%;
  display: block;
}
#form-offer-create .form-group #offer_bonus {
  width: 75px;
  background: #fcfcf6;
  border: 2px solid #e0e0e0;
  border-radius: 5px;
  vertical-align: bottom;
  font-size: 18px;
  padding: 5px;
  margin: 0 10px;
  text-align: center;
  margin-top: -5px;
  font-weight: 500;
  height: 40px;
}
#form-offer-create .form-group #offer_other_bonus {
  width: 100%;
  background: #fcfcf6;
  border: 2px solid #e0e0e0;
  border-radius: 5px;
  vertical-align: bottom;
  font-size: 14px;
  padding: 5px 10px;
  margin: 0;
  text-align: left;
  margin-top: -5px;
  font-weight: 500;
  height: 55px;
}
#form-offer-create .form-group .separator {
  background: transparent;
  border: 0;
  border-top: 1px dashed #ddd;
  height: 1px;
  margin: 30px 0;
}
#form-offer-create .form-group .custom-radio .custom-control-label {
  font-size: 13px;
  color: #5b6069;
}
#form-offer-create .form-group .custom-radio .custom-control-label:before,
#form-offer-create .form-group .custom-radio .custom-control-label:after {
  top: 1px;
}
#form-offer-create .form-group .custom-radio .custom-control-input:checked ~ .custom-control-label::before,
#form-offer-create .form-group .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #0c968b;
}
#form-offer-create .form-group .custom-radio .custom-control-label::before,
#form-offer-create .form-group .custom-radio .custom-control-label::after {
  box-shadow: none !important;
}
#form-offer-create .input-group {
  background: #fcfcf6;
  border: 2px dashed #e0e0e0;
  border-radius: 5px;
}
#form-offer-create .input-group > .custom-file {
  height: 50px;
}
#form-offer-create .input-group .custom-file-label {
  text-align: center;
  font-size: 12px;
  background: #fcfcf6;
  color: #5ce149;
  border: 0;
  padding: 15px 5px;
  height: auto;
}
#form-offer-create .input-group .custom-file-label img {
  margin-right: 10px;
}
#form-offer-create .input-group .custom-file-label:after {
  display: none;
}
#form-offer-create .input-group .custom-file-input {
  border: 0;
}
.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
.wrap {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
.text-ellipsis-two-lines {
  /* autoprefixer: off */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
}
.text-ellipsis-one-line {
  /* autoprefixer: off */
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
}
.honor-white-space {
  white-space: pre-line;
}
@media screen and (max-width: 992px) {
  header .nav a {
    font-size: 13px;
    padding: 5px 10px;
  }
  #login-popup #welcome-col,
  #login-popup #login-col {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  #login-popup #welcome-col img {
    height: 45px;
    margin: 20px 0;
  }
  #login-popup #welcome-col .medium {
    margin-bottom: 20px;
  }
  #login-popup form {
    margin-bottom: 0;
  }
  #login-popup form .btn-blue {
    margin-top: 20px;
  }
}
@media screen and (max-width: 767px) {
  .main-page .offer-nav form {
    margin: 10px 0 15px;
  }
  .main-page .offer-nav .nav {
    display: block;
    text-align: center;
    margin-bottom: 5px;
  }
  .main-page .offer-nav .nav .nav-item {
    display: inline-block;
  }
  .main-page .offer-nav .nav .nav-item a {
    padding: 20px 0 15px;
  }
}
@media screen and (max-width: 576px) {
  header {
    text-align: center;
  }
  header .nav {
    text-align: center;
  }
  .main-page .offer-nav .nav-item {
    margin: 0 10px;
  }
  .main-page .offer-nav #refresh {
    margin: 0 10px;
  }
}
